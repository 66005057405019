import { Button, Card, Checkbox, Container, FormControlLabel, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Zoom } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import SnackbarComponent from '../components/SnackbarComponent'
import DeleteIcon from '@mui/icons-material/Delete';
import { DataContext } from '../contexts/DataContext';
import { TableVirtuoso } from 'react-virtuoso';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import { exportToCSV } from '../utils/utils';
// import exportToCSV from '@utils/utils.js'

const card = {
    padding: '24px',
}
const sxCheckbox = {
    marginTop: 1,
    marginBottom: 1
}
const sxAddScanBtn = {
    // backgroundColor: 'seagreen',
    marginTop: 3,
    marginLeft: 7
}
const sxSaveBtn = {
    // backgroundColor: 'seagreen',
    margin: 3
}
const sxDeleteBtn = {
    color: 'red',
    margin: 0,
    marginLeft: 3
}
const sxMarginLeft = {
    marginLeft: 5
}
const sxMarginRight = {
    marginRight: 5
}

const scannedTRMAExample = {
    TRMA: '123', Devices: [
        { serial: '123', Drives: ['123', '456', '789'] },
        { serial: '456', Drives: ['111', '222', '333'] }
    ]
}

export default function ServerGenealogy() {
    const { createServerGenealogy, getAllServerGenealogy } = useContext(DataContext)

    const defaultFormState = {
        TRMA: '',
        DeviceSerialNo: '',
        DriveSerialNo: '',
        errorTRMA: false,
        errorDeviceSerialNo: false,
        errorDriveSerialNo: false,
        helperTextTRMA: '',
        helperTextDeviceSerialNo: '',
        helperTextDriveSerialNo: '',
        multiDevices: false,
        multiDrives: false,
        scannedTRMAs: [],
        scannedDevices: [],
        scannedDrives: [],
    }
    const [formState, setFormState] = useState(defaultFormState)

    const defaultAllScannedItems = []
    const [allScannedItems, setAllScannedItems] = useState({
        columns: [
            { width: 200, label: 'TRMA', dataKey: 'TRMA', },
            { width: 200, label: 'Device Serial', dataKey: 'DeviceSerial', },
            { width: 200, label: 'Drive Serial', dataKey: 'DriveSerial', },
            { width: 200, label: 'Created By', dataKey: 'createdByDisplayName', },
            { width: 200, label: 'Email', dataKey: 'createdByEmail', },
            { width: 200, label: 'Created Date', dataKey: 'dateCreated', },
        ],
        data: defaultAllScannedItems
    })
    const [searchQuery, setSearchQuery] = useState('');
    const [notFilteredDrives, setNotFilteredDrives] = useState([])
    const [filteredDrives, setFilteredDrives] = useState([]);

    // Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: ''
    })

    const [isSubmitting, setIsSubmitting] = useState(false)


    const handleChange = (e) => {

        setFormState({
            ...formState,
            [e.target.name]: e.target.value.toUpperCase(),
            errorTRMA: false,
            errorDeviceSerialNo: false,
            errorDriveSerialNo: false,
            helperTextTRMA: '',
            helperTextDeviceSerialNo: '',
            helperTextDriveSerialNo: '',
        })
    }
    const handleCheckbox = (e) => {
        // console.log('checked: ', e.target.name)
        if (!formState.TRMA) {
            setFormState({
                ...formState,
                errorTRMA: true,
                helperTextTRMA: 'Please enter TRMA'
            })
            document.getElementById('TRMA').focus()
            return
        }
        switch (e.target.name) {
            case 'multiDevices':
                document.getElementById('DeviceSerialNo').focus()
                setFormState({
                    ...formState,
                    multiDevices: !formState.multiDevices
                })
                break;
            case 'multiDrives':
                document.getElementById('DriveSerialNo').focus()
                setFormState({
                    ...formState,
                    multiDrives: !formState.multiDrives
                })
                break;

            default:
                break;
        }
    }

    const handleDelete = (itemToDelete, trmaIndex, deviceIndex, driveIndex) => {
        // console.log('delete this item: ', itemToDelete)
        // console.log('delete trmaIndex: ', trmaIndex)
        // console.log('delete deviceIndex: ', deviceIndex)
        // console.log('delete driveIndex: ', driveIndex)
        // console.log('delete from: ', formState.scannedTRMAs)

        let newScannedTRMAs = [...formState.scannedTRMAs];
        switch (itemToDelete) {
            case 'TRMA':
                newScannedTRMAs.splice(trmaIndex, 1);
                break;
            case 'Device':
                newScannedTRMAs[trmaIndex].Devices.splice(deviceIndex, 1);
                break;
            case 'Drive':
                newScannedTRMAs[trmaIndex].Devices[deviceIndex].Drives.splice(driveIndex, 1);
                break;

            default:
                // console.log('Invalid itemToDelete:', itemToDelete);
                break;
        }
        // console.log('Updated scannedTRMAs:', newScannedTRMAs);
        setFormState({
            ...formState,
            scannedTRMAs: newScannedTRMAs
        })
    }

    function isAlreadyScanned(allScannedTRMAs) {
        let existingScannedRecord = null

        allScannedTRMAs.forEach(TRMA => {
            TRMA.found = ''
            TRMA.multiDriveNotChecked = ''
            TRMA.alreadyScanned = ''

            // console.log(TRMA)
            // if (TRMA.TRMA === formState.TRMA) {
            //     existingScannedRecord = TRMA
            //     existingScannedRecord.found = 'TRMA'
            //     return existingScannedRecord
            // }
            TRMA.Devices.forEach(device => {
                // if (device.serial === formState.DeviceSerialNo && !formState.multiDevices) {
                // if (device.serial === formState.DeviceSerialNo) {

                //     existingScannedRecord = TRMA
                //     existingScannedRecord.found = 'Device Serial'
                //     console.log('existingScannedRecord:', existingScannedRecord)
                //     return existingScannedRecord

                // }
                device.Drives.forEach(drive => {
                    // if (drive === formState.DriveSerialNo && !formState.multiDrives) {
                    if (drive === formState.DriveSerialNo) {
                        existingScannedRecord = TRMA
                        existingScannedRecord.found = 'Drive Serial'
                        // console.log('existingScannedRecord:', existingScannedRecord)
                        return existingScannedRecord
                    }
                    // if (drive !== formState.DriveSerialNo && !formState.multiDrives) {
                    if (drive !== formState.DriveSerialNo) {
                        if (drive !== formState.DriveSerialNo) {
                            return
                        }
                        existingScannedRecord = TRMA
                        existingScannedRecord.multiDriveNotChecked = 'Please check the checkbox for multiple Drives to add to current Device.'
                        // console.log('its new drive but checkbox for multiple Drives is not checked')
                        return existingScannedRecord
                    }
                    // if (drive === formState.DriveSerialNo) {
                    //     existingScannedRecord = TRMA
                    //     existingScannedRecord.alreadyScanned = `Drive Serial # ${drive} was already scanned.`
                    //     return existingScannedRecord
                    // }
                })
            })

        })
        return existingScannedRecord
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!isSubmitting) {
            setIsSubmitting(true)

            // const scannedTRMAExample = [{
            //     TRMA: '123', Devices: [
            //         { serial: '123', Drives: ['123', '456', '789'] },
            //         { serial: '456', Drives: ['111', '222', '333'] }
            //     ]
            // }]
            if (!formState.TRMA || !formState.DeviceSerialNo || !formState.DriveSerialNo) {

                setFormState({
                    ...formState,
                    errorTRMA: !formState.TRMA ? true : false,
                    helperTextTRMA: !formState.TRMA ? '* Required field' : '',

                    errorDeviceSerialNo: !formState.DeviceSerialNo ? true : false,
                    helperTextDeviceSerialNo: !formState.DeviceSerialNo ? '* Required field' : '',

                    errorDriveSerialNo: !formState.DriveSerialNo ? true : false,
                    helperTextDriveSerialNo: !formState.DriveSerialNo ? '* Required field' : ''
                })
                setIsSubmitting(false)
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'TRMA #, Device Serial # and Drive Serial # are required fields.'
                })
                return
            }
            // check for already scanned Drives in all Devices and all trmas
            var wasScanned = isAlreadyScanned(formState.scannedTRMAs)
            // console.log(wasScanned)
            if (wasScanned !== null) {
                // if (wasScanned.alreadyScanned) {
                //     setSnackbar({
                //         open: true,
                //         severity: 'error',
                //         message: wasScanned.alreadyScanned
                //     })
                // }
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: wasScanned.alreadyScanned ? wasScanned.alreadyScanned :
                        wasScanned.multiDriveNotChecked ? `${wasScanned.multiDriveNotChecked}` : `${wasScanned.found} # was already scanned for current scan batch.`

                })
                setIsSubmitting(false)
                return
            }

            const existingTRMA = formState.scannedTRMAs.find(item => item.TRMA === formState.TRMA)
            const existingTRMAIndex = formState.scannedTRMAs.findIndex(item => item.TRMA === formState.TRMA)
            if (existingTRMA) {
                // TRMA exists, add to current Devices
                const existingDevice = existingTRMA.Devices.find(device => device.serial === formState.DeviceSerialNo);
                const existingDeviceIndex = existingTRMA.Devices.findIndex(device => device.serial === formState.DeviceSerialNo);
                if (existingDevice) {
                    // Device exists, add to current Drives
                    const existingDrive = existingDevice.Drives.find(drive => drive === formState.DriveSerialNo)
                    const existingDriveIndex = existingDevice.Drives.findIndex(drive => drive === formState.DriveSerialNo)
                    if (existingDrive) {
                        // do nothing
                    } else {
                        var newScannedTRMAs = formState.scannedTRMAs
                        newScannedTRMAs[existingTRMAIndex].Devices[existingDeviceIndex].Drives.push(formState.DriveSerialNo)
                        // Update scanned Drives for current TRMA and device
                        setFormState({
                            ...formState,
                            scannedTRMAs: newScannedTRMAs
                        })
                    }
                } else {

                    var newScannedTRMAs = formState.scannedTRMAs
                    newScannedTRMAs[existingTRMAIndex].Devices.push({ serial: formState.DeviceSerialNo, Drives: [formState.DriveSerialNo] })
                    // Add new Device to current TRMA
                    setFormState({
                        ...formState,
                        scannedTRMAs: newScannedTRMAs
                    })
                }
            } else {
                var newScannedTRMAs = formState.scannedTRMAs
                newScannedTRMAs.push({ TRMA: formState.TRMA, Devices: [{ serial: formState.DeviceSerialNo, Drives: [formState.DriveSerialNo] }] })
                // TRMA is new, create a new TRMA record
                setFormState({
                    ...formState,
                    scannedTRMAs: newScannedTRMAs
                })
            }

            setIsSubmitting(false)
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        if (!isSubmitting) {
            setIsSubmitting(true)
            // console.log('saving scan results in database:', formState.scannedTRMAs)
            createServerGenealogy(formState.scannedTRMAs)
                .then(succ => {
                    console.log('succ', succ.message)
                    setFormState(defaultFormState)
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        // message: 'Successfully saved scan results in database.'
                        message: succ.message
                    })
                    setIsSubmitting(false)
                })
                .catch(err => {
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: err.message
                    })
                    setIsSubmitting(false)
                })
        }
    }
    const handleDisplayAll = (e) => {
        if (!isSubmitting) {
            setIsSubmitting(true)

            getAllServerGenealogy()
                .then(succ => {
                    setAllScannedItems({
                        ...allScannedItems,
                        data: succ.data
                    })
                    setSnackbar({
                        open: true,
                        severity: 'success',
                        // message: 'Successfully retrieved scan results from database.'
                        message: succ.message
                    })
                    setIsSubmitting(false)
                })
                .catch(err => {
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: err.message
                    })
                    setIsSubmitting(false)
                })
        }

    }
    const handleSearch = (searchFor) => {

        if (searchFor.length > 0) {
            const lowercasedFilter = searchFor.toLowerCase();
            const filteredData = notFilteredDrives.filter(item =>
                item.TRMA.toLowerCase().includes(lowercasedFilter) ||
                item.DeviceSerial.toLowerCase().includes(lowercasedFilter) ||
                item.DriveSerial.toLowerCase().includes(lowercasedFilter)
            );
            setFilteredDrives(filteredData);
            // setAllScannedItems({
            //     ...allScannedItems,
            //     data: filteredData
            // })
            console.log('this is filtered data:', filteredData)
        }
    }

    function fixedHeaderContent() {
        return (
            <TableRow>
                {allScannedItems.columns.map((column) => (
                    <TableCell
                        key={column.dataKey}
                        variant="head"
                        align={column.numeric || false ? 'right' : 'left'}
                        style={{ width: column.width }}
                        sx={{
                            backgroundColor: 'background.paper',
                        }}
                    >
                        {column.label}
                    </TableCell>
                ))}
            </TableRow>
        );
    }

    function rowContent(_index, row) {
        return (
            <React.Fragment>
                {allScannedItems.columns.map((column) => (
                    <TableCell
                        key={column.dataKey}
                        align={column.numeric || false ? 'right' : 'left'}
                    >
                        {row[column.dataKey]}
                    </TableCell>
                ))}
            </React.Fragment>
        );
    }


    const handleChangeOrSetDefault = (e, allDrives) => {
        // handleSearch(e.target.value)
        setSearchQuery(e.target.value)
        setNotFilteredDrives(allDrives)
    }

    const DisplayAllItems = () => {
        let allDrives = []
        let countServers = 0
        allScannedItems.data.forEach(trma => {
            trma.Devices.forEach(server => {
                countServers++
                server.Drives.forEach(drive => {
                    allDrives.push({ TRMA: trma.TRMA, DeviceSerial: server.serial, DriveSerial: drive, createdByDisplayName: trma.createdByDisplayName, createdByEmail: trma.createdByEmail, dateCreated: moment(trma.dateCreated.toDate()).format('DD MMM YYYY HH:mm') })
                })
            })
        })
        var dataToDisplay = []
        var csvTitle = 'Server Genealogy'
        var csvHeaders = [
            'TRMA',
            'DeviceSerial',
            'DriveSerial',
            'createdByDisplayName',
            'createdByEmail',
            'dateCreated',
        ]

        if (searchQuery.length > 0) {
            const lowercasedFilter = searchQuery.toLowerCase();
            const filteredData = notFilteredDrives.filter(item =>
                item.TRMA.toLowerCase().includes(lowercasedFilter) ||
                item.DeviceSerial.toLowerCase().includes(lowercasedFilter) ||
                item.DriveSerial.toLowerCase().includes(lowercasedFilter)
            );
            dataToDisplay = filteredData
        } else {
            dataToDisplay = allDrives
        }
        return (
            <Paper>
                <Table size='small'>
                    <br /><br />
                    <p style={{ marginLeft: 20 }}>{`All Scanned TRMAs: ${allScannedItems.data.length},  All Scanned Servers: ${countServers},  All Scanned Drives: ${allDrives.length}`}</p>

                    <Grid container direction='row' justifyContent='flex-end' alignItems='center'>

                        {searchQuery.length > 0 ? <p style={{ marginTop: 0, marginRight: 20, paddingTop: 0 }}>{`Found ${dataToDisplay.length} records for "${searchQuery}"`}</p> : dataToDisplay.length === 0 ? <p style={{ marginTop: 0, marginRight: 20, paddingTop: 0 }}>{`Nothing found for ${searchQuery}`}</p> : null}
                        <form noValidate autoComplete="off">
                            <TextField sx={sxMarginRight} style={{ marginTop: 0 }}
                                size='small'
                                label="Search"
                                // variant="filled"
                                value={searchQuery}
                                onChange={e => handleChangeOrSetDefault(e, allDrives)}
                                inputProps={{ maxLength: 20 }}
                                // fullWidth
                                // margin="normal"
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <Tooltip title='Search' placement='right'>
                                //                 <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => handleSearch(searchQuery)}>
                                //                     <SearchIcon />
                                //                 </IconButton>
                                //             </Tooltip>
                                //         </InputAdornment>
                                //     ),
                                // }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title='Clear' placement='bottom' TransitionComponent={Zoom}>
                                                <IconButton type="button" aria-label="clear"
                                                    disabled={searchQuery.length <= 0}
                                                    onClick={() => setSearchQuery('')}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <Tooltip title='Download CSV' placement='bottom' TransitionComponent={Zoom}>
                                <IconButton type='button' sx={sxMarginRight}
                                    aria-label='download csv'
                                    onClick={() => exportToCSV(csvTitle, csvHeaders, dataToDisplay)}
                                >
                                    <SaveAltIcon />
                                </IconButton>
                            </Tooltip>
                        </form>
                    </Grid>
                    <Paper style={{ height: 600, width: '100%' }}>

                        <TableVirtuoso
                            // data={rows}
                            data={dataToDisplay}
                            fixedHeaderContent={fixedHeaderContent}
                            itemContent={rowContent}
                        />
                    </Paper>
                    {/* {allDrives.map((extractedDrive, i) => (
                    <p key={i}>{`TRMA: ${extractedDrive.TRMA}, Device Serial: ${extractedDrive.DeviceSerial}, Drive Serial ${extractedDrive.DriveSerial}, Created By Display Name:${extractedDrive.createdByDisplayName}, Created By Email: ${extractedDrive.createdByEmail}, Date Created: ${extractedDrive.dateCreated}`}</p>
                ))} */}
                </Table>
            </Paper>
        )
    }
    const DevicesDrivesDisplay = (scannedTRMAs) => {
        // &nbsp;    // Regular space
        // &ensp;    // Two spaces gap
        // &emsp;    // Four spaces gap
        return (
            <div>
                <p>Scan Results:</p>
                {scannedTRMAs.map((scan, index) => (
                    <div key={index} >
                        <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
                            <p style={{ marginTop: 4, marginBottom: 3 }}>{`TRMA # ${scan.TRMA}`}</p>
                            <Tooltip title="Delete whole TRMA" placement="right">
                                <IconButton aria-label="delete" size="small" sx={sxDeleteBtn} onClick={() => handleDelete('TRMA', index, -1, -1)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        {scan.Devices.map((device, i) => (
                            <div key={i}>
                                <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
                                    <p style={{ marginTop: 4, marginBottom: 3 }}>&emsp;&emsp;Device Serial # {device.serial}</p>
                                    <Tooltip title="Delete this Device and its Drives" placement="right">
                                        <IconButton aria-label="delete" size="small" sx={sxDeleteBtn} onClick={() => handleDelete('Device', index, i, -1)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                {device.Drives.map((drive, j) => (
                                    <Grid key={j} container direction='row' justifyContent='flex-start' alignItems='center'>
                                        <p style={{ marginTop: 4, marginBottom: 3 }}>&emsp;&emsp;&emsp;&emsp;Drive Serial # {drive}</p>
                                        <Tooltip title="Delete Drive" placement="right">
                                            <IconButton aria-label="delete" size="small" sx={sxDeleteBtn} onClick={() => handleDelete('Drive', index, i, j)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                ))}
                            </div>

                        ))}
                    </div>
                ))
                }
            </div>
        )
    }
    return (
        <div>
            <h6 className='txtCenter'>Server Genealogy</h6>

            <Container component="main" maxWidth="xl">

                <Card sx={card}>

                    <form id='serverGenealogy' onSubmit={handleSubmit} noValidate>  {/* there was noValidate */}
                        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
                            <Grid container item direction='column' justifyContent='center' alignItems='flex-start' xs={6}>

                                <TextField
                                    autoFocus
                                    disabled={formState.multiDevices}
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    autoComplete='off'
                                    // fullWidth
                                    id="TRMA"
                                    label="TRMA #"
                                    name="TRMA"
                                    value={formState.TRMA}
                                    error={formState.errorTRMA}
                                    helperText={formState.helperTextTRMA}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                />

                                {/* <FormControlLabel control={<Checkbox checked={formState.multiDevices} onChange={handleCheckbox} name='multiDevices' />} label="Scan multiple Devices for this TRMA?" sx={sxCheckbox} /> */}
                                <TextField
                                    autoFocus
                                    disabled={formState.multiDrives}
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    autoComplete='off'
                                    // fullWidth
                                    id="DeviceSerialNo"
                                    label="Device Serial #"
                                    name="DeviceSerialNo"
                                    value={formState.DeviceSerialNo}
                                    error={formState.errorDeviceSerialNo}
                                    helperText={formState.helperTextDeviceSerialNo}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                />


                                {/* <FormControlLabel control={<Checkbox checked={formState.multiDrives} onChange={handleCheckbox} name='multiDrives' />} label="Scan multiple Drives for this Device?" sx={sxCheckbox} /> */}

                                <TextField
                                    autoFocus
                                    // disabled={formState.multiDevices}
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    autoComplete='off'
                                    // fullWidth
                                    id="DriveSerialNo"
                                    label="Drive Serial #"
                                    name="DriveSerialNo"
                                    value={formState.DriveSerialNo}
                                    error={formState.errorDriveSerialNo}
                                    helperText={formState.helperTextDriveSerialNo}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                />

                                <Button
                                    sx={sxAddScanBtn}
                                    // type="submit"
                                    // fullWidth
                                    disabled={isSubmitting}
                                    id='saveAndFinish'
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    add scan
                                </Button>

                            </Grid>

                            <Grid container item direction='column' justifyContent='flex-start' alignItems='flex-start' xs={6}>

                                <Grid item>
                                    <Button
                                        sx={sxSaveBtn}
                                        // type="submit"
                                        // fullWidth
                                        disabled={isSubmitting}
                                        id='displayAllScanned'
                                        variant="contained"
                                        color="primary"
                                        onClick={handleDisplayAll}
                                    >
                                        display all scanned items
                                    </Button>
                                </Grid>

                                {formState.scannedTRMAs.length > 0 ?
                                    <Grid item>
                                        {DevicesDrivesDisplay(formState.scannedTRMAs)}
                                        <br />
                                        <Button
                                            sx={sxSaveBtn}
                                            // type="submit"
                                            // fullWidth
                                            disabled={isSubmitting}
                                            id='saveInDB'
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSave}
                                        >
                                            save scan results in database
                                        </Button>
                                    </Grid>
                                    : null}
                                {/* <p>{`Scanned Devices: ${formState.TRMA}`}</p>
                                <p>{`Scanned Drives: ${formState.TRMA}`}</p> */}
                            </Grid>
                        </Grid>
                    </form>

                    {allScannedItems.data.length > 0 && DisplayAllItems()}

                </Card>

                <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

            </Container>
        </div>
    )
}
