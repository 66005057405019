import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, LinearProgress, Tooltip, Typography } from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import csvToJson from 'csvtojson'
import SnackbarComponent from '../components/SnackbarComponent';
import { auth, functions, storage } from '../config/fbConfig';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import moment from 'moment';
import { DataContext } from '../contexts/DataContext';
import csvImage from '../images/CSV_icon_small.png'
import { httpsCallable } from 'firebase/functions';

const windowWidth = window.innerWidth
const card = {
    padding: '24px',
}
const csvCard = {
    backgroundColor: '#FFF0F0',
    border: '1px solid #BFB4B4',
    padding: '16px',
    margin: 5,
}
const input = {
    display: 'none',
}
const sxDivider = {
    // backgroundColor: '#182751',
    marginTop: 4,
    marginBottom: 4,
}
const dropProgress = {
    visibility: 'hidden',
    marginBottom: '15px',
}
const downloadButton = {
    margin: 2,
}
const successButton = {
    // backgroundColor: '#54D430',
    // backgroundColor: '#16A011',
    backgroundColor: '#2C7F27',
    margin: 2,
}
const sxFileIcon = {
    maxWidth: 50,
}
const sxTableAndTd = {
    border: '1px solid #333',
    padding: 5,
}

export default function GUTSproject() {

    const { createGutsFile, getGutsFiles } = useContext(DataContext)
    const [hoverDropzone, setHoverDropzone] = useState('azure')

    const [trmaData, setTrmaData] = useState([])

    const [currentFiles, setCurrentFiles] = useState({
        toSave: null,
        saved: []
    })
    const [allSavedFiles, setAllSavedFiles] = useState([])

    // Dialog
    const [dialog, setDialog] = useState({
        open: false,
        content: '',
    })
    // Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: ''
    })

    useEffect(() => {
        getGutsFiles()
            .then(succ => {
                setAllSavedFiles(succ.data)
            })
        return () => {
        }
    }, [])


    function fileTypes(fileName) {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }
    const dragOver = (e) => {
        e.preventDefault();
        console.log('dragOver')
    }
    var dragsCount = 0
    const dragEnter = (e) => {
        e.preventDefault();
        dragsCount++
        console.log('dragEnter:', dragsCount)
        setHoverDropzone('#BABFBF')
    }
    const dragLeave = (e) => {
        e.preventDefault();
        dragsCount--
        console.log('dragLeave:', dragsCount)
        if (dragsCount === -1) {
            setHoverDropzone('azure')
        }
    }
    const readCSVFile = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        // reader.readAsArrayBuffer(file);
        // reader.readAsBinaryString(file);
        reader.readAsText(file);
        // reader.onload = () => resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
        // reader.onload = () => resolve((reader.result.replace(/^data:.+;base64,/, '')));
        reader.onload = () => resolve((reader.result));
        // reader.onload = () => {
        //     // Parse CSV file
        //     csv.parse(reader.result, (err, data) => {
        //         console.log("Parsed CSV data: ", data);
        //         resolve(data);
        //     });
        // };
        reader.onerror = error => reject(error);
    })

    const handleCloseAndCancelDialog = (e) => {
        setCurrentFiles({ toSave: null, saved: [] })
        setDialog({
            open: false,
            content: '',
        });
    };
    const handleSaveOnlyDialog = (e) => {
        setDialog({
            open: false,
            content: '',
        });
        saveFileInDB(currentFiles.toSave, [])
            .then(succ => {
                console.log('Saved only file - successfully')
            })
            .catch(err => {
                console.log('There was an error while saving the file')
            })
    };
    const handleProceedDialog = (e) => {
        setDialog({
            open: false,
            content: '',
        });


        // // available fields
        // // var raw = JSON.stringify({
        // // //   "Waybill_No": "",
        // // //   "Deal_No": "",
        // //   "Partner_No": "",//that will be GUTS ID
        // // //   "Manifest_Type": " ",
        // // //   "Locked": false,
        // // //   "Quote_Type": "Revenue Share",
        // // //   "Active": true,
        // // //   "Provider_ID": "RMAPRO104",
        // // //   "Department_Code": "GENERAL",
        // // //   "Site_ID": "",
        // // //   "Site_Code_1": "",
        // // //   "Site_Code_2": "",
        // // //   "Customer_No": "RMAC100284",
        // // //   "Project_Manager": "RMAU100067",
        // // //   "Due_Date": "0001-01-01T00:00:00Z",
        // // //   "Architect_User": "",
        // // //   "Sales_User": "RMAU100067",
        // //   "Name": "IBM",
        // //   "Name_2": "Pawelski",
        // // //   "Phone_Preffix": "+44",
        // //   "Phone_Number": "007",
        // // //   "Telex_No": "",
        // //   "Address": "Pawelski Address",
        // //   "Address_2": "Pawelski Address 2",
        // //   "City": "Cobh the great island",
        // //   "County": "Cork",
        // //   "Post_Code": "007",
        // //   "Country_Code": "CO",
        // // //   "Last_Updated": "2023-05-10T13:15:05.977Z",
        // // //   "Updated_User": "WISEWEB1",
        // // //   "Our_Account_No": "",
        // // //   "Own_Commissions": 0,
        // // //   "Client_Commissions": 0,
        // // //   "Processing_Cost": 0,
        // // //   "Contact": "Pawelski",
        // // //   "Logistics_Cost": 0,
        // //   "Email": "pawelg@wisetek.net",
        // // //   "Gross_Value": 0,
        // // //   "Margin": 0,
        // // //   "Value_Figure": 0,
        // // //   "Web": "adfaarqrwerwer2r2wesfddddddddddddddddddddddddddddddddddddddddddddddddddddd",
        // // //   "Latitude": 0,
        // // //   "Longitude": 0,
        // // //   "Status_Code": "RMACNT088",
        // // //   "Last_Status_Date": "2023-05-10T11:17:20.813Z",
        // // //   "Last_Status_User": "WISEWEB1",
        // // //   "Insert_Week": 19,
        // // //   "Insert_Year": 2023,
        // // //   "Update_Week": 19,
        // // //   "Inactive_Date": "0001-01-01T00:00:00Z",
        // // //   "Inactive_Week": 0,
        // // //   "Inactive_User": "",
        // // //   "Attribute_1": "RMAPRO134",
        // // //   "Attribute_3": "",
        // // //   "Attribute_4": "",
        // // //   "Attribute_13": "0001-01-01T00:00:00Z",
        // // //   "Attribute_14": "0001-01-01T00:00:00Z",
        // // //   "Attribute_15": "0001-01-01T00:00:00Z",
        // // //   "Quote_Name": "",
        // // //   "Quote_Number": "",
        // // //   "Erasure_Type": " ",
        // // //   "Erasure_Complete": false,
        // // //   "Surrender_Letter": false,
        // // //   "Surrender_Manifest": false,
        // // //   "Equipment_Match": " ",
        // // //   "New_OPPID": "50228560 SwapID 6062G ",
        // // //   "Old_OPPID": "R201604270536",
        // // //   "OEM_Server_Qty": 0,
        // // //   "Description": "",
        // // //   "Comments": "Blah blah blah",
        // // //   "Internal_TAT": 0,
        // // //   "External_TAT": 0,
        // // //   "TAT_Trend": 0,
        // // //   "No_Of_Attachments": 0,
        // // //   "Cert_Of_Destruction_Required": false,
        // // //   "Cert_Of_Erasure_Required": false,
        // // //   "COD_Issued": false,
        // // //   "Turn_Out_Required": false,
        // // //   "Turn_Out_Due_Date": "0001-01-01T00:00:00Z",
        // // //   "Invoice_Required": false,
        // // //   "Invoice_Due_Date": "0001-01-01T00:00:00Z",
        // // //   "Request_Type": 0,
        // // //   "WWS_Scan_File_Received": false,
        // // //   "Tracking_Number": "",
        // // //   "Request_Type_Value": "",
        // // //   "Cost_Centre": "",
        // // //   "Account_Manager": "",
        // // //   "Scheduled_by": "",
        // // //   "Scheduled_Date_Time": "0001-01-01T00:00:00Z",
        // // //   "Expected_Container_Type": " ",
        // // //   "Expected_Container_Qty": 0,
        // // //   "Location_Code": "",
        // // //   "Scheduled_Collection_Date": "0001-01-01T00:00:00Z",
        // // //   "Scheduled_Arrival_Date": "0001-01-01T00:00:00Z",
        // // //   "SLA_Receiving": "",
        // // //   "SLA_Asset": "",
        // // //   "SLA_Audit": "",
        // // //   "SLA_Turnout": "",
        // // //   "SLA_Days": "",
        // // //   "Receiving_Report_Sent": false,
        // // //   "Basic_Audit_Report_Sent": false,
        // // //   "Tech_Audit_Report_Sent": false,
        // // //   "Return_Address": "",
        // // //   "Return_Address_2": "",
        // // //   "Return_City": "",
        // // //   "Return_County": "",
        // // //   "Return_Post_Code": "",
        // // //   "Return_Country_Code": ""
        // // });





        console.log('Saved file successfully, going to creating TRMAs')
        var dropProgress = document.getElementById('dropProgress');
        dropProgress.style.visibility = 'visible';
        var trmasCreated = []
        var extractedTRMAnumbers = []

        const callable = httpsCallable(functions, 'postOneTRMA');

        var createdCount = 0

        trmaData.forEach(oneTRMA => {
            var dataToSend = {
                objToPost: {
                    "Active": true,
                    "Comments": oneTRMA['Record ID'],//that will be GUTS ID we need to decide which field in NAV we will use
                    "Name": oneTRMA['First Name'],
                    "Name_2": oneTRMA['Second Name'],
                    "Phone_Number": oneTRMA['Phone Number'],
                    "Address": oneTRMA['Address'],
                    "Address_2": oneTRMA['Address 1'],
                    "City": oneTRMA['City'],
                    "County": oneTRMA['County'],
                    "Post_Code": oneTRMA['Zip Code'],
                    "Country_Code": oneTRMA['Country'],
                    "Email": oneTRMA['Email Address']
                }
            }
            console.log('trying to submit TRMA:', dataToSend.objToPost)
            callable(dataToSend, auth)
                .then(succ => {
                    console.log('TRMA successfully created: ', JSON.parse(succ.data))
                    createdCount++
                    var parsedResult = JSON.parse(succ.data)
                    trmasCreated = [...trmasCreated, parsedResult]
                    extractedTRMAnumbers = [...extractedTRMAnumbers, parsedResult.ID]
                    if (createdCount === trmaData.length) {
                        console.log(`All ${createdCount} TRMAs created: ${trmasCreated}`)
                        console.log('extractedTRMAnumbers', extractedTRMAnumbers)
                        dropProgress.style.visibility = 'hidden';
                        saveFileInDB(currentFiles.toSave, trmasCreated)
                            .then(succ => {
                                console.log('Saved file')
                            })
                            .catch(err => {
                                console.log('There was an error while saving the file', err)
                            })
                    }
                })
                .catch(
                    console.log('Error occurred.')
                )
        })

    };

    function saveFileInDB(fileToSave, createdTRMASorEmptyArr) {
        return new Promise((resolve, reject) => {

            var dropProgress = document.getElementById('dropProgress');
            dropProgress.style.visibility = 'visible';

            uploadBytes(ref(storage, 'csvFilesGUTS/' + moment().format('YYYYMMDD_HHmmss_') + fileToSave.name), fileToSave)
                .then(snap => {
                    // console.log(snap.metadata.name)

                    getDownloadURL(ref(storage, snap.ref.fullPath))
                        .then(url => {
                            setCurrentFiles({ ...currentFiles, saved: [...currentFiles.saved, { name: snap.metadata.name, url: url }] })
                            createGutsFile({ name: snap.metadata.name, url: url, createdTRMA: createdTRMASorEmptyArr.length > 0 ? createdTRMASorEmptyArr : [] })
                            dropProgress.style.visibility = 'hidden';
                            resolve()
                        })
                        .catch(err => {
                            // A full list of error codes is available at
                            // https://firebase.google.com/docs/storage/web/handle-errors
                            switch (err.code) {
                                case 'storage/object-not-found':
                                    console.log("File doesn't exist")
                                    break;
                                case 'storage/unauthorized':
                                    console.log("User doesn't have permission to access the object")
                                    break;
                                case 'storage/canceled':
                                    console.log('User canceled the upload')
                                    break;

                                // ...

                                case 'storage/unknown':
                                    console.log('Unknown error occurred, inspect the server response')
                                    break;
                            }
                            dropProgress.style.visibility = 'hidden';
                            reject()
                        })
                })
                .catch(err => {
                    console.log(err)
                    dropProgress.style.visibility = 'hidden';
                })
        })
    }

    const fileDrop = (e) => {
        e.preventDefault();
        console.log('file Drop')
        // dragsCount to change the color of the dropzone on dragEnter/dragLeave
        dragsCount = 0
        setHoverDropzone('azure')
        // var dropProgress = document.getElementById('dropProgress');
        // dropProgress.style.visibility = 'visible';
        // for ref please check this article here: https://blog.logrocket.com/create-a-drag-and-drop-component-with-react-dropzone/
        const files = e.dataTransfer.files;
        if (files.length > 1) {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Only one file allowed.',
            })
            // dropProgress.style.visibility = 'hidden';
        }
        else {
            [...files].forEach(file => {
                var fileType = String(fileTypes(file.name));
                const maxSize = 30000000
                var header = "Record ID,First Name,Second Name,Address,Address 1,City,County,Zip Code,Country,Phone Number,Email Address"
                var numberOfColumns = 11
                // console.log(header)
                if (fileType.toLowerCase() === 'csv') {
                    // console.log(file)
                    if (file.size >= maxSize) {
                        setSnackbar({
                            open: true,
                            severity: 'warning',
                            message: 'Selected file is too big to process.',
                        })
                        // dropProgress.style.visibility = 'hidden';
                    }
                    else {
                        readCSVFile(file)
                            .then(result => {
                                // console.log(result.split('\n')[0])
                                var uploadedHeader = (result.split('\n')[0]).replace(/(\r\n|\n|\r)/gm, '')
                                // console.log('uploadedHeader', uploadedHeader)
                                if (header === uploadedHeader) {
                                    csvToJson().fromString(result)
                                        .then((jsonObj) => {
                                            // console.log(jsonObj);
                                            var processData = [];
                                            //change true false fields
                                            jsonObj.forEach(dataObject => {
                                                if (Object.keys(dataObject).length > numberOfColumns) {
                                                    setSnackbar({
                                                        open: true,
                                                        severity: 'error',
                                                        message: 'Found additional columns, please use the correct template.',
                                                    })
                                                    // dropProgress.style.visibility = 'hidden';
                                                    return
                                                }
                                                // console.log(dataObject)
                                                processData.push(dataObject)
                                            })
                                            console.log(processData)
                                            setCurrentFiles({ ...currentFiles, toSave: file })
                                            setTrmaData(processData)

                                            setDialog({
                                                open: true,
                                                content: 'Found ' + processData.length + ' row(s) in this CSV. Please select the next step, save file or create TRMA and save this file?'
                                            })
                                            // dropProgress.style.visibility = 'hidden';
                                        })
                                }
                                else {
                                    setSnackbar({
                                        open: true,
                                        severity: 'error',
                                        message: 'Template does not match. Please use correct template.',
                                    })
                                    // dropProgress.style.visibility = 'hidden';
                                }
                            })
                    }
                }
                else {
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: 'Please make sure that your are uploading a CSV file.',
                    })
                    dropProgress.style.visibility = 'hidden';
                }
            })
        }
    }

    const fileSelect = (e) => {
        // console.log('eeeee:', e)
        // console.log('e.targettttttt:', e.target)
        var { target: { files } } = e;
        if ([...files].length < 1) {
            console.log('no file selected')
            return
        }
        // var dropProgress = document.getElementById('dropProgress');
        // dropProgress.style.visibility = 'visible';

        [...files].forEach(file => {
            var fileType = String(fileTypes(file.name));
            const maxSize = 30000000
            var header = "Record ID,First Name,Second Name,Address,Address 1,City,County,Zip Code,Country,Phone Number,Email Address"
            var numberOfColumns = 11
            // console.log(header)
            if (fileType.toLowerCase() === 'csv') {
                // console.log(file)
                if (file.size >= maxSize) {
                    setSnackbar({
                        open: true,
                        severity: 'warning',
                        message: 'Selected file is too big to process.',
                    })
                    // dropProgress.style.visibility = 'hidden';
                }
                else {
                    readCSVFile(file)
                        .then(result => {
                            // console.log(result.split('\n')[0])
                            var uploadedHeader = (result.split('\n')[0]).replace(/(\r\n|\n|\r)/gm, '')
                            if (header === uploadedHeader) {
                                csvToJson().fromString(result)
                                    .then((jsonObj) => {
                                        // console.log(jsonObj);
                                        var processData = [];
                                        //change true false fiedls
                                        jsonObj.forEach(dataObject => {
                                            if (Object.keys(dataObject).length > numberOfColumns) {
                                                setSnackbar({
                                                    open: true,
                                                    severity: 'error',
                                                    message: 'Found additional columns, please use the correct template.',
                                                })
                                                // dropProgress.style.visibility = 'hidden';
                                                return
                                            }
                                            // console.log(dataObject)
                                            processData.push(dataObject)
                                        })
                                        console.log(processData)
                                        setCurrentFiles({ ...currentFiles, toSave: file })
                                        setTrmaData(processData)

                                        setDialog({
                                            open: true,
                                            content: 'Found ' + processData.length + ' row(s) in this CSV. Please select the next step, save file or create TRMA and save this file?'
                                        })
                                        // dropProgress.style.visibility = 'hidden';
                                    })
                            }
                            else {
                                setSnackbar({
                                    open: true,
                                    severity: 'error',
                                    message: 'Template does not match. Please use correct template.',
                                })
                                // dropProgress.style.visibility = 'hidden';
                            }
                        })
                }
            }
            else {
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: 'Please make sure that your are uploading a CSV file.',
                })
                // dropProgress.style.visibility = 'hidden';
            }
        })
        e.target.value = null // to allow selecting the same file over and over
    }

    return (
        <div className='txtCenter'>
            <h6 className='txtCenter'>GUTS Project</h6>
            <Container component="main" maxWidth="xl">

                <Card sx={card}>

                    <Typography variant="subtitle1" gutterBottom>
                        Create TRMAs in NAV by selecting or dropping the CSV file into the area below
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        TRMAs will be automatically created in NAV and for testing purpose they will be displayed on the screen
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        <b>Note:</b> Required headers in the csv file: Record ID, First Name, Second Name, Address, Address 1, City, County, Zip Code, Country, Phone Number, Email Address
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        "Record ID" on the CSV file is the same as GUTS ID exported from Google sheet
                    </Typography>
                    {/* // NAV fields: ID(20),Name(50),Name_2(50),Address(50),Address_2(50),City(50),County(50),Post_Code(20),Country_Code(10),Phone_Number(30),Email(80) // Customer Reference Number
    //        GUTS ID, First Name, Second Name, Address, Address 1, City, County, Zip Code, Country, Phone Number, Email // TODO: Partner_No or Customer_No */}
                    <div id='dropzone' style={{
                        height: 250,
                        textAlign: 'center',
                        margin: windowWidth > 600 ? '50px 50px 50px 50px' : '40px 0px 40px 0px',
                        padding: windowWidth > 600 ? 10 : '10px 5px 10px 5px',
                        backgroundColor: hoverDropzone,
                        border: '2px dashed #182751',// wisetek navy color
                        borderRadius: 20,
                    }}//style={sxDropzone} //style={{backgroundColor:hoverDropzone}}
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}>
                        <LinearProgress id='dropProgress' sx={dropProgress} />
                        <GetAppIcon />
                        <p>Drag & Drop CSV file here</p>
                        <h4>Or</h4>
                        <input type="file" accept=".csv" multiple={false} id="addFiles" style={input} onChange={fileSelect} />
                        <label htmlFor="addFiles">
                            <Button variant='outlined' color='inherit' component="span" startIcon={<AttachFileIcon />} >Select CSV file</Button>
                        </label>
                    </div>


                    {currentFiles.saved.length > 0 ? <Grid container
                        direction="column"
                        justifyContent="center"
                        alignItems="center" >
                        <Divider sx={sxDivider} />
                        <Typography variant="subtitle1" gutterBottom>
                            Below you can see the name of the file that was saved during this session:
                        </Typography>
                        <ol>
                            {currentFiles.saved.map((savedFile, i) => (
                                <li key={i}>
                                    <a href={savedFile.url}>{savedFile.name}</a>
                                </li>
                            ))}
                        </ol>
                    </Grid> : null}

                    {allSavedFiles.length > 0 ? <Grid container
                        direction="column"
                        justifyContent="center"
                        alignItems="center" >
                        <Divider sx={sxDivider} />
                        <Typography variant="subtitle1" gutterBottom>
                            All saved files:
                        </Typography>

                        {allSavedFiles.map((savedFile, i) => (
                            <Grid container key={i}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center">
                                <Grid item>
                                    <a href={savedFile.url} style={{ textDecoration: 'none', color: 'inherit', fontSize: '0.7em' }}>
                                        <Card style={csvCard}>
                                            <Tooltip title='Download'>
                                                <div>
                                                    <img src={csvImage} style={sxFileIcon} />
                                                    <p>{savedFile.name}</p>
                                                </div>
                                            </Tooltip>
                                        </Card>
                                    </a>
                                </Grid>
                                {savedFile.createdTRMA.length > 0 ? <Grid item><h6 style={{ margin: 5 }}>Created:</h6></Grid> : null}
                                {savedFile.createdTRMA.length > 0 ?
                                    <div>
                                        <h6></h6>
                                        <table style={sxTableAndTd}>
                                            <thead>
                                                <tr>
                                                    <th style={sxTableAndTd}>Record ID</th>
                                                    <th style={sxTableAndTd}>TRMA ID</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {savedFile.createdTRMA.map(trma => (
                                                    <tr key={trma.ID}>
                                                        <td style={sxTableAndTd}>{trma.Comments}</td>
                                                        <td style={sxTableAndTd}>{trma.ID}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    : null}
                            </Grid>
                        ))}

                    </Grid> : null}

                </Card>
            </Container>

            <Dialog
                open={dialog.open}
                onClose={handleCloseAndCancelDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Success</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialog.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseAndCancelDialog} color="warning" sx={downloadButton}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSaveOnlyDialog} color="primary" sx={downloadButton}>
                        Save File Only
                    </Button>
                    <Button variant="contained" onClick={handleProceedDialog} sx={successButton}>
                        Create trma and save file
                    </Button>
                </DialogActions>
            </Dialog>

            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
        </div>
    )
}
