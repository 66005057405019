import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import React from 'react';
// import MuiAlert from '@material-ui/lab/Alert';
// import { Snackbar } from '@material-ui/core';


export default function SnackbarComponent(props) {
    // console.log(props.snackbar.open)
    // console.log(props.snackbar.severity)
    // console.log(props.snackbar.message)
    //Snackbar
    // const [snackbar, setSnackbar] = useState({
    //     open: props.snackbar.open,
    //     severity: props.snackbar.severity,
    //     message: props.snackbar.message,
    // });
    // function Alert(props) {
    //     // return <MuiAlert elevation={6} variant="filled" {...props} />;
    //     return <Alert {...props} />;
    // };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.setSnackbar({
            open: false,
            // severity: 'info',
            // message: '',
        })
    };

    return (
        <div>
            {/* <Snackbar open={true} autoHideDuration={6000} onClose={handleClose}> */}
            <Snackbar open={props.snackbar.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}>
                {/* <Alert onClose={handleClose} severity={'success'}> */}
                <Alert onClose={handleClose} severity={props.snackbar.severity}>
                    {/* {'props.snackbar.message'} */}
                    {props.snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    )
}
