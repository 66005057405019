import { Link, Typography } from '@mui/material'
import React from 'react'

const footer = {
    backgroundColor: '#ECEFF1',
    padding: 10,
    marginTop: 'auto',
}

export default function Footer() {
    return (
        <footer style={footer}>
            <Typography style={footer} variant="body2" color="textSecondary" align="center" mt={8} >
                {'© Pawel Gajewski | '}
                <Link color="inherit" href="https://wisetek.net/">
                    Wisetek
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </footer>
    )
}
