import { Grid } from '@mui/material'
import React from 'react'
import wiseToolsLogo from '../images/WiseToolsLogo.png'
import gears from '../images/mechanicalGearGroup.gif'

const imgStyle = {
    maxWidth: 300
    // objectFit: 'contain'
}

export default function Dashboard() {
    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="center">
            <h6 className='txtCenter'>Home</h6>
            <div style={{ maxWidth: 300 }}>
                <img id="logo" src={wiseToolsLogo} alt="Wise Tools Logo" style={{ maxWidth: '100%', marginBottom: 10 }} />
                <img src={gears} style={imgStyle} alt='Wisetek gear logo' />
            </div>
            {<div className='txtCenter'>Welcome to "WiseTools" - one place for all Wisetek productivity needs.....
                {/* {[...new Array(120)]
                    .map(() => `Cras mattis consectetur purus sit amet fermentum.
                            Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                            Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                    )
                    .join('\n')} */}
            </div>}
        </Grid>
    )
}
