import { addDoc, collection, doc, getDocs, orderBy, query, setDoc, writeBatch } from "firebase/firestore";
import React, { createContext, Component } from "react";
import { auth, db } from "../config/fbConfig";
import moment from "moment";

export const DataContext = createContext()

class DataContextProvider extends Component {
    state = {}

    componentDidMount() {
        auth.onAuthStateChanged(user => {
            if (user) {
                // console.log(JSON.stringify(user))
                // this.getDropdownLists();
            }
        })
    }
    //Utility Function
    delay = ms => new Promise(res => setTimeout(res, ms))

    createGenealogy = (data) => {
        console.log('Add data:', data)
        return new Promise(async (resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                // const q = query(collection(db, 'genealogy'))
                // const newEntryRef = await addDoc(collection(db, 'genealogy'), {
                addDoc(collection(db, 'genealogy'), {
                    //opt available:
                    // JobId: '',
                    // DeviceSerialNo: '',
                    // ChildSerialNo: '',
                    // multiDevices: false,
                    // ErrorJobId: false,
                    // ErrorDeviceSerialNo: false,
                    // ErrorChildSerialNo: false,
                    // helperTextJobId: '',
                    // helperTextDeviceSerialNo: '',
                    // helperTextChildSerialNo: '',
                    // multipleDeviceSerials: [],
                    dateCreated: new Date(),
                    JobId: data.JobId,
                    DeviceSerialNo: data.DeviceSerialNo,
                    ChildSerialNo: data.ChildSerialNo,
                    createdByDisplayName: user.displayName,
                    createdByEmail: user.email,
                    shredded: false,
                    // multiDevices: data.multiDevices,
                })
                    .then(docRef => {
                        resolve({ message: 'Successfully created new record in genealogy table.', docRef: docRef })
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    getGenealogy = () => {
        return new Promise(async (resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                // console.log(user)
                // const q = query(collection(db, 'genealogy'))
                // const newEntryRef = await addDoc(collection(db, 'genealogy')

                getDocs(query(collection(db, 'genealogy'), orderBy('dateCreated', 'desc')))
                    .then(snap => {
                        var data = []
                        snap.forEach(doc => {
                            data = [...data, { ...doc.data(), id: doc.id }]
                        })
                        resolve({ message: 'Successfully retrieved data from genealogy table.', data: data })
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    createGutsFile = (data) => {
        console.log('Add data:', data)
        return new Promise(async (resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                addDoc(collection(db, 'gutsFiles'), {

                    dateCreated: new Date(),
                    createdTRMA: data.createdTRMA,
                    name: data.name,
                    url: data.url,
                })
                    .then(docRef => {
                        resolve({ message: 'Successfully created new record in GUTS table.', docRef: docRef })
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }
    getGutsFiles = () => {
        return new Promise(async (resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                // console.log(user)
                // const q = query(collection(db, 'genealogy'))
                // const newEntryRef = await addDoc(collection(db, 'genealogy')

                getDocs(query(collection(db, 'gutsFiles'), orderBy('dateCreated', 'desc')))
                    .then(snap => {
                        var data = []
                        snap.forEach(doc => {
                            data = [...data, { ...doc.data(), id: doc.id, dateCreated: moment(doc.data().dateCreated.toDate()).format('DD MMM YYYY') }]
                        })
                        resolve({ message: 'Successfully retrieved data from GUTS table.', data: data })
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })
    }

    createServerGenealogy = (data) => {
        console.log('Add data:', data)
        return new Promise(async (resolve, reject) => {
            var user = auth.currentUser;

            if (!user) {
                reject('User not authenticated.');
                return;
            }

            try {
                const batch = writeBatch(db); // Initialize a batch write  MAX allowed is 500

                // Iterate through each TRMA object in the data array
                data.forEach(async (trmaObj) => {
                    const { TRMA, Devices } = trmaObj; // Extract relevant properties

                    // Create a new document for each TRMA
                    const newDocRef = doc(collection(db, 'serverGenealogy'));
                    batch.set(newDocRef, {
                        TRMA,
                        Devices,
                        dateCreated: new Date(),
                        createdByDisplayName: user.displayName,
                        createdByEmail: user.email,
                    });
                });

                // Commit the batch write
                await batch.commit();
                resolve({ message: 'Successfully created new records in Server Genealogy table.' });
            } catch (err) {
                console.error(err);
                // reject(err.message);
                reject(err);
            }
            // if (user) {
            //     // const q = query(collection(db, 'genealogy'))
            //     // const newEntryRef = await addDoc(collection(db, 'genealogy'), {

            //     addDoc(collection(db, 'serverGenealogy'), {
            //         //opt available:
            //         // TRMA: '',
            //         // DeviceSerialNo: '',
            //         // DriveSerialNo: '',
            //         // errorTRMA: false,
            //         // errorDeviceSerialNo: false,
            //         // errorDriveSerialNo: false,
            //         // helperTextTRMA: '',
            //         // helperTextDeviceSerialNo: '',
            //         // helperTextDriveSerialNo: '',
            //         // multiDevices: false,
            //         // multiDrives: false,
            //         // scannedTRMAs: [],
            //         // scannedDevices: [],
            //         // scannedDrives: [],
            //         dateCreated: new Date(),
            //         createdByDisplayName: user.displayName,
            //         createdByEmail: user.email,

            //     })
            //         .then(docRef => {
            //             resolve({ message: 'Successfully created new record in Server Genealogy table.', docRef: docRef })
            //         })
            //         .catch(err => {
            //             console.log(err);
            //             reject(err.message)
            //         })
            // }
        })
    }

    getAllServerGenealogy = () => {

        return new Promise(async (resolve, reject) => {
            var user = auth.currentUser;

            if (user) {
                // console.log(user)
                // const q = query(collection(db, 'genealogy'))
                // const newEntryRef = await addDoc(collection(db, 'genealogy')

                getDocs(query(collection(db, 'serverGenealogy'), orderBy('dateCreated', 'desc')))
                    .then(snap => {
                        var data = []
                        snap.forEach(doc => {
                            data = [...data, { ...doc.data(), id: doc.id }]
                        })
                        resolve({ message: 'Successfully retrieved data from server genealogy table.', data: data })
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err.message)
                    })
            }
        })

    }

    render() {
        return (
            <DataContext.Provider value={{
                ...this.state,
                delay: this.delay,
                createGenealogy: this.createGenealogy,
                getGenealogy: this.getGenealogy,
                createGutsFile: this.createGutsFile,
                getGutsFiles: this.getGutsFiles,
                createServerGenealogy: this.createServerGenealogy,
                getAllServerGenealogy: this.getAllServerGenealogy,
            }}>
                {this.props.children}
            </DataContext.Provider>
        );
    }
}

export default DataContextProvider;