import React from 'react';
import { LinearProgress } from '@mui/material';

const sxLinearProgress = {
    width: '100%',
    marginTop: '10px',
}

export default function Loading() {

    return (
        <div style={sxLinearProgress}>
            <LinearProgress />
            {/* <LinearProgress color="secondary" /> */}
        </div>
    );
}