import React from 'react'
import BarcodeScan from '../components/BarcodeScan'

export default function Scan() {
    return (
        <div >
            <h6 className='txtCenter'>Scan</h6>
            <BarcodeScan />
        </div>
    )
}
