import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBsyk-x6cLCvF7Ap0mxINRl-2EUfAsgH2k",
    authDomain: "wisetek-wisetools.firebaseapp.com",
    projectId: "wisetek-wisetools",
    storageBucket: "wisetek-wisetools.appspot.com",
    messagingSenderId: "205446290297",
    appId: "1:205446290297:web:7a3316aaa939ffbdfaada8",
    measurementId: "G-VJLHTVT89N"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const anal = getAnalytics(firebaseApp)
export const auth = getAuth(firebaseApp)
export const db = getFirestore(firebaseApp)
export const functions = getFunctions(firebaseApp)
export const storage = getStorage(firebaseApp)
// console.log(anal)
// console.log(auth)
// console.log(db)
// console.log(functions)
// console.log(storage)
export default firebaseApp;