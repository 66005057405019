export const exportToCSV = (title, headers, data) => {
    const csvRows = [];

    // Add the headers
    csvRows.push(headers.join(','));

    // Add the data
    data.forEach(item => {
        const values = headers.map(header => item[header]);
        csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `${title}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};