import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthContextProvider from './contexts/AuthContext';
import './App.css';
import Home from './pages/Home';
import DataContextProvider from './contexts/DataContext';
import SignIn from './pages/SignIn';
import { CssBaseline } from '@mui/material';
import Footer from './components/Footer';
import NotFoundPage from './pages/NotFoundPage';

const rootStyle = {
  backgroundColor: '#ECEFF1',
  minHeight: '95vh',
  padding: 15
}

function App() {
  return (
    <div>
      <div className='root'>
        <BrowserRouter>
          <AuthContextProvider>
            <DataContextProvider>
              <CssBaseline />
              <Routes>
                <Route exact path='/signIn' element={<SignIn />}></Route>
                <Route exact path='*' element={<NotFoundPage />}></Route>


                <Route exact path='/' element={<Home />}></Route>
              </Routes>
            </DataContextProvider>
          </AuthContextProvider>
        </BrowserRouter>
      </div>
      <Footer />
    </div>
  );
}

export default App;
