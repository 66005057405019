import { Button } from '@mui/material'
import { httpsCallable } from 'firebase/functions'
import React, { useState } from 'react'
import { auth, functions } from '../config/fbConfig'
import moment from 'moment'

const downloadButton = {
    margin: 2,
}

export default function Testing() {

    const [fullDataSet, setFullDataSet] = useState([])

    const [gotFullDataSet, setGotFullDataSet] = useState(false)

    const getOneItem = async (e) => {
        const getStuff = httpsCallable(functions, 'getOneTableItemFromCODData')
        var data = { text: 'empty string', SerialNo: 'MASSYDODATESTSKI' }
        getStuff(data, auth)
            .then(success => {
                // console.log('One data item:', success)
                var cos = JSON.parse(success.data)
                console.log('One data item parsed:', cos)
                console.log('One item, cos.value.. :', cos.value)

                console.log(`Last successful api call - GET - was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`)
            })
            .catch(err => {
                console.log('Error GET One item...', err.message)
                console.log(`Last FAILED api call - GET - was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`, err)
            })
    }

    const getAllItems = (e, newNextLink) => {
        // console.log('newNextLink:', newNextLink)
        const getStuff = httpsCallable(functions, 'getAllTableCODData')
        var dataToSend = { text: 'empty string', nextLink: newNextLink === undefined ? "https://nav2.wisetek.net:8748/live/ODataV4/Company('Wisetek%20Ltd%20Live%20Company')/COD_Data" : newNextLink }
        // console.log('dataToSend', dataToSend)
        getStuff(dataToSend, auth)
            .then(success => {
                // console.log('Many items:', success)
                var cos = JSON.parse(success.data)
                // console.log('Many items parsed::', cos)
                // console.log('Many items, cos.value.. ', cos.value)

                console.log(`Last successful api call - GET - was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`)
                // console.log('cos.nextLink is:', cos['@odata.nextLink'])
                // console.log('need to save current array to state and go get the rest of data...')
                var newArr1 = fullDataSet
                console.log('newArr1.length:', newArr1.length)
                var newArr2 = cos.value
                console.log('newArr2.length:', newArr2.length)

                newArr1.push(...newArr2)

                setFullDataSet(newArr1)
                if (cos['@odata.nextLink']) {
                    dataToSend.nextLink = cos['@odata.nextLink']
                    getAllItems({ text: 'empty string' }, cos['@odata.nextLink'])
                } else {
                    setGotFullDataSet(true)
                    console.log('finished getting all the data: We have: ' + fullDataSet.length)
                    console.log(JSON.stringify(fullDataSet))
                }
            })
            .catch(err => {
                console.log('Error GET Many items..', err.message)
                console.log(`Last FAILED api call - GET - was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`, err)
            })
    }

    const patchOneItem = (e) => {

        const patchStuff = httpsCallable(functions, 'patchOneTableItemFromCODData')
        var dataToSend = { text: 'empty string', SerialNo: 'MASSYDODATEST', objToUpdate: { "TRMAID": "PAWELSKIDARRRENSKI" } } //require Serial_No - primary key and data to update
        // console.log('dataToSend', dataToSend)
        patchStuff(dataToSend, auth)
            .then(success => {
                // console.log('Many items:', success)
                var cos = JSON.parse(success.data)
                console.log('Updated item parsed::', cos)

                console.log(`Last successful api call - PATCH - was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`)
            })
            .catch(err => {
                console.log('Error PATCH one item..', err.message)
                console.log(`Last FAILED api call - PATCH - was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`, err)
            })
    }

    const postOneItem = (e) => {

        const postStuff = httpsCallable(functions, 'postOneTableItemToCODData')
        var dataToSend = {
            text: 'empty string', SerialNo: 'MASSYDODATEST', objToPost: { //require Serial_No - primary key
                "Serial_No": "MASSYDODATESTSKITWO",
                "Device_Serial": "TESTSKITT",
                "TRMAID": "TESTSKITT",
                "Shred_Date": "2023-05-03T23:00:00Z",
                "Shred_Job_ID": "TESTSKI",
                "Source": "test",
                "Import_Date": "2023-05-03T00:00:00Z",
                "Date_Dispositioned": "2023-05-03T00:00:00Z"
            }
        }
        // console.log('dataToSend', dataToSend)
        postStuff(dataToSend, auth)
            .then(success => {
                // console.log('Many items:', success)
                var cos = JSON.parse(success.data)
                console.log('Posted item parsed::', cos)

                console.log(`Last successful api call - POST - was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`)
            })
            .catch(err => {
                console.log('Error POST item..', err.message)
                console.log(`Last FAILED api call - POST - was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`, err)
            })
    }

    const delOneItem = (e) => {

        const delStuff = httpsCallable(functions, 'delOneTableItemFromCODData')
        var dataToSend = { text: 'empty string', SerialNo: 'MASSYDODATESTSKITWO' } //require Serial_No - primary key 
        // console.log('dataToSend', dataToSend)
        delStuff(dataToSend, auth)
            .then(success => {
                console.log('success:', success.status)
                // var cos = JSON.parse(success.data)
                // console.log('Deleted item parsed::', cos)

                console.log(`Last successful api call - DEL - was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`)
            })
            .catch(err => {
                console.log('Error DEL one item..', err.message)
                console.log(`Last FAILED api call - DEL - was on: ${moment().format('HH:mm:ss DD MMM YYYY')}`, err)
            })
    }

    return (
        <div>
            <h6 className='txtCenter'>Testing</h6>

            <div>
                <Button variant='outlined' onClick={getOneItem} sx={downloadButton}>Get one item</Button>
                <Button variant='outlined' onClick={getAllItems} sx={downloadButton}>Get all items</Button>
                <Button variant='outlined' onClick={patchOneItem} sx={downloadButton}>Patch one item</Button>
                <Button variant='outlined' onClick={postOneItem} sx={downloadButton}>Post one item</Button>
                <Button variant='outlined' onClick={delOneItem} sx={downloadButton}>Delete one item</Button>

                {gotFullDataSet ?// <h6>Fetched full dataset which has {fullDataSet.length} records.</h6>
                    <ol >
                        {fullDataSet.map((item, i) => (
                            <li key={i}>
                                {/* {JSON.stringify(item)} */}
                                <h6>
                                    Date_Dispositioned:{item.Date_Dispositioned},
                                    Device_Serial:{item.Device_Serial},
                                    ETag:{item.ETag},
                                    Import_Date:{item.Import_Date},
                                    Serial_No:{item.Serial_No},
                                    Shred_Date:{item.Shred_Date},
                                    Shred_Job_ID:{item.Shred_Job_ID},
                                    Source:{item.Source},
                                    TRMAID:{item.TRMAID}
                                </h6>
                            </li>
                        ))}
                    </ol>
                    : null}
            </div>
        </div>
    )
}
