import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import React, { useRef, useState } from 'react'
import Html5QrCodeScanner from './Html5QrCodeScanner';


const textStyle = {
    wordWrap: 'break-word', // to wrap long barcode on mobile
}

export default function BarcodeScan() {

    const [searching, setSearching] = useState(false)
    const [scanText, setScanText] = useState('')
    const [scanResult, setScanResult] = useState(null)
    const [modalOpen, setModalOpen] = useState(false);


    const maxMobileDeviceScreenWidth = 500
    const windowWidth = window.innerWidth
    const calcWindowWidth = parseInt(windowWidth / 1.5)

    const handleCloseModal = () => {
        setModalOpen(false);
    }

    const handleNewScanResult = (decodedText, decodedResult) => {
        // handle decoded results here
        console.log('decodedText', decodedText)
        console.log('decodedResult', decodedResult)
        // setScanText('Decoded Text: ' + decodedText)
        setScanText(decodedText)
        // setScanResult('Decoded Result: ' + JSON.stringify(decodedResult))
        setScanResult(decodedResult)
        setModalOpen(true)
        // {
        //     decodedText: '65G4SQ2',
        //     result: {
        //         text: '65G4SQ2',
        //         format: { format: 3, formatName: 'CODE_39' },
        //         debugData: { decoderName: 'BarcodeDetector' }
        //     }
        // }
    };


    const handleCloseModalCorrectSerial = () => {
        // const stopButton = document.getElementById('html5-qrcode-button-camera-stop').click()
        document.getElementById('html5-qrcode-button-camera-stop').click()
        handleCloseModal()
        // console.log(stopButton)
        // stopButton.click()
        // html5QrCode.stop().then((ignore) => {
        //     // QR Code scanning is stopped.
        //     console.log('QR Code scanning is stopped.')
        // }).catch((err) => {
        //     // Stop failed, handle it.
        // });
    }
    const handleCloseModalIncorrectSerial = () => {
        // const stopButton = document.getElementById('html5-qrcode-button-camera-stop').click()
        // document.getElementById('html5-qrcode-button-camera-stop').click()
        setScanText('')
        setScanResult(null)
        handleCloseModal()
        // console.log(stopButton)
        // stopButton.click()
        // html5QrCode.stop().then((ignore) => {
        //     // QR Code scanning is stopped.
        //     console.log('QR Code scanning is stopped.')
        // }).catch((err) => {
        //     // Stop failed, handle it.
        // });
    }

    return (
        <div>
            {/* <p>Barcode scan</p> */}

            {windowWidth > maxMobileDeviceScreenWidth ? <Typography variant='body1' gutterBottom><b>Note:</b> Scan functionality might not work correctly on desktop, please switch to mobile device!</Typography> : null}

            <Html5QrCodeScanner id='codeScanner'
                fps={1}
                qrbox={{ width: calcWindowWidth, height: 100 }}
                disableFlip={false}
                qrCodeSuccessCallback={handleNewScanResult}
            />

            <Dialog
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="scan-result-dialog-title"
            // aria-describedby="scan-result-dialog-description"
            >
                <DialogTitle id="scan-result-dialog-title" style={{ color: 'green', textAlign: 'center', fontWeight: 'bold' }}>
                    {`${scanText}`}
                </DialogTitle>
                <DialogTitle>
                    {`Is this correct?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="scan-result-dialog-description">
                        {scanResult ? `${scanText} was created using:` : ''}
                    </DialogContentText>
                    <DialogContentText>
                        {scanResult ? `${scanResult.result.format.formatName} font.` : ''}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='error' onClick={handleCloseModalIncorrectSerial}>No, Scan again</Button>
                    <Button variant='outlined' color='success' onClick={handleCloseModalCorrectSerial} autoFocus>Yes, that's correct</Button>
                </DialogActions>
            </Dialog>

            <Typography variant="h6" gutterBottom style={textStyle}>{scanText}</Typography>

            <Typography variant="subtitle1" gutterBottom>Screen size: {windowWidth}px</Typography>
            <Typography variant="subtitle2" gutterBottom>Calculated scan width area: {calcWindowWidth}px</Typography>
            {/* <Typography variant="h3">{scanResult}</Typography> */}

        </div>
    )
}
