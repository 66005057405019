import { Button, Card, Grid } from '@mui/material'
import React from 'react'

const sxCard = {
    maxWidth: 500,
    padding: '50px',
    marginTop: '50px',
}
const sxAHref = {
    textDecoration: 'none'
}
const sxH1 = {
    fontSize: 22,
    fontWeight: 300,
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '0 0 16px',
}
const sxH2 = {
    color: '#ffa100',
    fontWeight: 'bold',
    fontSize: 16,
    margin: '0 0 8px',
}
export default function NotFoundPage() {
    return (
        <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Card sx={sxCard}>
                <h2 style={sxH2}>404 - WiseTools</h2>
                <h1 style={sxH1}>Page Not Found</h1>
                <img src="./wiseTools192.png" alt="WiseTools Logo" />
                <br />
                <p>The specified page was not found on this website. Please check the URL for mistakes and try again.</p>
                <br />
                {/* <a href="javascript:history.back()">Back Home</a> */}
                <div className='txtCenter'>
                    <a href="/" style={sxAHref}>
                        <Button variant='outlined'>Back Home</Button>
                    </a>
                </div>
                <br />
            </Card>
        </Grid>
    )
}
