import { browserSessionPersistence, OAuthProvider, setPersistence, signInWithPopup, signInWithRedirect, signOut } from 'firebase/auth';
import React, { Component, createContext } from 'react'
import firebaseApp, { auth, db } from '../config/fbConfig';

export const AuthContext = createContext();

class AuthContextProvider extends Component {
    state = {
        loading: true,
        displayName: '',
        uid: '',
        firstName: '',
        lastName: '',
        email: '',
        initials: '',
        isAuthenticated: false,
        emailVerified: false,
        authError: '',
        roles: []

    }

    componentDidMount() {
        auth.onAuthStateChanged(user => {
            // console.log(user)
            if (user) {
                // console.log('Logged in : ' + JSON.stringify(user));

                this.setState({
                    displayName: user.displayName,
                    email: user.email,
                    emailVerified: user.emailVerified,
                    loading: false,
                    uid: user.uid,
                    isAuthenticated: true,
                })
                //get user db data

                // db.collection('users').add({

                //     displayName: user.displayName,
                //     email: user.email,

                // })
                //     .then(success => {

                //         console.log('added user to firebase db', success)
                //     })
                //     .catch(error => {
                //         console.log("Error getting document: ", error);
                //     })
            }
            else {
                // User not signed in
                console.log("User not authenticated...");
                this.setState({
                    loading: false,
                    displayName: '',
                    uid: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    initials: '',
                    isAuthenticated: false,
                    emailVerified: false,
                    authError: '',
                    roles: []
                });
            }
        });
    };

    signIn = (credentials) => {
        return new Promise((resolve, reject) => {

            // auth.setPersistence(firebaseApp.auth.Auth.Persistence.SESSION) Web version 8
            setPersistence(auth, browserSessionPersistence) // Web version 9
                .then(() => {
                    const provider = new OAuthProvider('microsoft.com')
                    provider.setCustomParameters({
                        prompt: "consent",
                        tenant: "5161909b-20f9-4b83-91f4-3c143661b423",
                        // tenant: "the tenant id provided by outlook",
                    })

                    signInWithPopup(auth, provider)
                        .then(resp => {
                            // console.log('resp:', resp)
                            // User is signed in.
                            // IdP data available in result.additionalUserInfo.profile.

                            // Get the OAuth access token and ID Token
                            const credential = OAuthProvider.credentialFromResult(resp);
                            const accessToken = credential.accessToken;
                            const idToken = credential.idToken;

                            // console.log('credential:', credential)
                            // console.log('accessToken:', accessToken)
                            // console.log('idToken:', idToken)
                            resolve({ isSuccess: true, text: 'Login successful.' })
                        })
                        .catch(error => {
                            switch (error.code) {
                                case 'auth/popup-closed-by-user':
                                    reject(`User closed Microsoft popup window.`);
                                    break;
                                case 'auth/invalid-email':
                                    reject(`Username ${credentials.email} is invalid.`);
                                    break;
                                case 'auth/user-disabled':
                                    reject(`Username ${credentials.email} has been disabled.`);
                                    break;
                                case 'auth/user-not-found':
                                    reject(`Username ${credentials.email} does not exist.`);
                                    break;
                                case 'auth/wrong-password':
                                    reject('Username or Password does not match.');
                                    break;
                                default:
                                    reject(error);
                                    break;
                            }
                        })
                    // auth.signInWithEmailAndPassword(
                    //     username,
                    //     credentials.password
                    // )
                    //     .then(resp => {
                    //         console.log('resp:', resp)
                    //         resolve('Login successful.')
                    //     })
                    //     .catch(error => {
                    //         switch (error.code) {
                    //             case 'auth/invalid-email':
                    //                 reject(`Username ${credentials.email} is invalid.`);
                    //                 break;
                    //             case 'auth/user-disabled':
                    //                 reject(`Username ${credentials.email} has been disabled.`);
                    //                 break;
                    //             case 'auth/user-not-found':
                    //                 reject(`Username ${credentials.email} does not exist.`);
                    //                 break;
                    //             case 'auth/wrong-password':
                    //                 reject('Username or Password does not match.');
                    //                 break;
                    //             default:
                    //                 reject(error);
                    //                 break;
                    //         }
                    //     })
                })
        })
    }

    logout = () => {
        return new Promise((resolve, reject) => {
            signOut(auth)
                .then(() => {
                    // Sign-out successful.
                    console.log('User SignOut...')
                    resolve()
                }).catch((error) => {
                    // An error happened.
                    console.log('SignOut error...', error)
                    reject(error)
                })
        })
    }


    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }



    render() {
        return (
            <AuthContext.Provider value={{
                ...this.state,
                signIn: this.signIn,
                logout: this.logout,
            }}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default AuthContextProvider;
