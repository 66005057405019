import { Button, Card, Container, Grid, Link, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import SnackbarComponent from '../components/SnackbarComponent';
import { AuthContext } from '../contexts/AuthContext';
import logo from '../images/mechanicalGearGroup.gif'
import wiseToolsLogo from '../images/WiseToolsLogo.png'
// import Button from '@material-ui/core/Button';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import TextField from '@material-ui/core/TextField';
// import Link from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';
// import { AuthContext } from '../../contexts/AuthContext';
// import { Redirect } from 'react-router-dom';
// import VarifyEmail from './VerifyEmail';
// import { Snackbar, Card, FormControlLabel, Checkbox } from '@material-ui/core';
// import MuiAlert from '@material-ui/lab/Alert';
// import Footer from '../Footer';
// import Loading from './Loading';
// import { DataContext } from '../../contexts/DataContext';



// const useStyles = makeStyles(theme => ({
const root = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#cfd8dc'
}
const paper = {
    // marginTop: theme.spacing(8),
    // marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 0,
}
const card = {
    // minWidth: '500px',
    marginTop: '50px',
    padding: '48px',
}
const avatar = {
    // margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
}
const form = {
    width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
}
const submit = {
    // margin: theme.spacing(3, 0, 2),
}
// }));

export default function SignIn(props) {
    // const classes = useStyles()

    // console.log(props)
    const navigate = useNavigate()
    const [state, setState] = useState({
        email: '',
        password: '',
        authError: '',
    });
    const { signIn, isAuthenticated, loading, email, roles } = useContext(AuthContext);

    const [snackbar, setSnackbar] = useState({
        // open: props.snackbar.open ? props.snackbar.open : false,
        // severity: props.snackbar.severity ? props.snackbar.severity : '',
        // message: props.snackbar.message ? props.snackbar.message : '',
        open: false,
        severity: 'info',
        message: ''
    })

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // gat all info
        console.log('Login Submission...')

        // Login user
        signIn(state)
            .then(resp => {
                console.log('Logged in.', resp);
            })
            .catch(error => {
                console.log(error);
                setState({
                    ...state,
                    authError: error
                })
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error
                })
            })
        document.getElementById('login-form').reset();
    }
    useEffect(() => {
        if (isAuthenticated) {
            return navigate('/', { replace: true })
        }
        // else {
        //     return navigate('/signIn', { replace: true })
        // }
        return () => { }
    }, [isAuthenticated])



    if (loading) {
        return (<Loading />)
    }
    else {
        if (isAuthenticated) {
            return navigate('/', { replace: true })
        } else {
            return (
                <div sx={root}>

                    <Container component="main" maxWidth="xs">

                        <Card sx={card}>
                            <div sx={paper}>
                                <br />
                                <div className='txtCenter'>
                                    <img id="logo" src={wiseToolsLogo} alt="Wise Tools Logo" style={{ maxWidth: '100%', marginBottom: 10 }} />
                                    <img id="logo" src={logo} alt="Logo" style={{ maxWidth: '90%', margin: 10 }} />
                                </div>
                                <br />
                                {/* <Typography component="h1" variant="h5">
                                Employees  - Sign in
                            </Typography> */}
                            </div>
                            <form id="login-form" sx={form} onSubmit={handleSubmit}>  {/* ther was noValidate */}
                                {/* <TextField
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                />
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 50 }}
                                /> */}
                                {/* <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Remember me"
                                /> */}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={submit}
                                    startIcon={
                                        <mat-icon role="img" style={{ width: 35, height: 35, backgroundColor: 'white', padding: 5 }} aria-hidden="true"><svg height="100%" viewBox="0 5 20 10" width="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false"><g fill="none"><path d="M0 0h9.504v9.504H0z" fill="#F25022"></path><path d="M10.496 0H20v9.504h-9.504z" fill="#7FBA00"></path><path d="M0 10.496h9.504V20H0z" fill="#00A4EF"></path><path d="M10.496 10.496H20V20h-9.504z" fill="#FFB900"></path></g></svg></mat-icon>
                                    }
                                >
                                    Sign In with work email credentials
                                </Button>
                                {/* <Grid container>
                                    <Grid item xs>
                                        <Link href="/forgotpassword" variant="body2">
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="/signup" variant="body2">
                                            "Don't have an account? Sign Up"
                                        </Link>
                                    </Grid>
                                </Grid> */}
                            </form>

                            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                        </Card>
                    </Container>
                </div>
            )
        }
    }
}