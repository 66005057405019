import * as React from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import { Tooltip } from '@mui/material';

// Note: to use back to top button you need to ad id='top' somewhere on top

const fabStyle = {
    backgroundColor: 'lightskyblue'
}

function ScrollTop(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 300,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#top');

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            });
        }
    };

    return (
        <Zoom in={trigger}>
            <Box onClick={handleClick} role="presentation" sx={{ position: 'fixed', bottom: 20, right: 20 }}>
                {children}
            </Box>
        </Zoom>
    );
}

export default function BackToTop(props) {
    return (
        <>
            <ScrollTop {...props}>
                <Tooltip title="Back to top" placement="left">
                    <Fab size="small" aria-label="scroll back to top" style={fabStyle}>
                        <KeyboardArrowUpIcon />
                    </Fab>
                </Tooltip>
            </ScrollTop>
        </>
    );
}


