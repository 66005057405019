import React, { useContext, useState } from 'react'
import { AppBar, Box, Container, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Slide, Toolbar, Typography, useScrollTrigger } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import BackToTop from '../components/BackToTop';
import SnackbarComponent from '../components/SnackbarComponent';
import Dashboard from './Dashboard';
import Scan from './Scan';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import Loading from '../components/Loading';
import CreateGenealogy from './CreateGenealogy';
import Testing from './Testing';
import GUTSproject from './GUTSproject';
import ServerGenealogy from './ServerGenealogy';

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}


const drawerWidth = 240;
const appBarStyle = { backgroundColor: '#182751' }
const drawerStyle = { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#ECEFF1', }
// const navItems = [{ label: 'Home', content: <Dashboard /> }, { label: 'Scan', content: <Scan /> }, { label: 'Sign Out', content: <Loading /> }];
const navItems = [
    { label: 'Home', content: <Dashboard /> },
    { label: 'Scan', content: <Scan /> },
    { label: 'Create Genealogy', content: <CreateGenealogy /> },
    { label: 'GUTS Project', content: <GUTSproject /> },
    { label: 'Server Genealogy', content: <ServerGenealogy /> },
];


export default function Home(props) {
    const navigate = useNavigate()

    const { logout, isAuthenticated, emailVerified, loading, email, roles } = useContext(AuthContext);

    const { window } = props;

    const [mobileOpen, setMobileOpen] = useState(false);

    const [show, setShow] = useState({
        index: 0,
        content: <Dashboard />
    })

    //Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: '',
    });

    const handleDrawerToggle = () => {
        // console.log('toggle open/close')
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                WiseTools
            </Typography>
            <Divider />
            <List>
                {navItems.map((item, itemIndex) => (
                    <ListItem key={itemIndex} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }} onClick={() => handleDrawerItemClick(item, itemIndex)}>
                            <ListItemText primary={item.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {email === 'pawelg@wisetek.net' ?
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }} onClick={() => handleDrawerItemClick({ label: 'Testing', content: <Testing /> }, -1)}>
                            <ListItemText primary="Testing" />
                        </ListItemButton>
                    </ListItem>
                    : null}
                <ListItem disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => handleDrawerItemClick('Sign Out', 0)}>
                        <ListItemText primary="Sign Out" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    )

    // const container = window !== undefined ? () => window().document.body : undefined;

    const handleDrawerItemClick = (goTo, goToIndex) => {
        // console.log(goTo)
        // console.log(goToIndex)
        // console.log(navItems.length)
        if (goTo === 'Sign Out') {
            logout()
        }

        // console.log('now we should show: ', goTo.label, 'with index: ', goToIndex, 'and content:', goTo.content)
        handleDrawerToggle()
        setShow({ index: goToIndex, content: goTo.content })
    }

    // console.log('isAuthenticated', isAuthenticated)
    if (isAuthenticated) {
        return (
            <div>
                <CssBaseline />
                <HideOnScroll {...props}>
                    <AppBar style={appBarStyle}>
                        <Toolbar>
                            <Typography variant="h6" component="div" >
                                {mobileOpen ? '' : 'WiseTools'}
                            </Typography>

                            <div style={{ marginLeft: 'auto' }}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={handleDrawerToggle}
                                // sx={{ mr: 2, display: { sm: 'none' } }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>

                            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}> */}
                            <Box>
                                <Drawer
                                    // container={container}
                                    anchor='right'
                                    variant="temporary"
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                    sx={{
                                        // display: { xs: 'block', sm: 'none' },
                                        '& .MuiDrawer-paper': drawerStyle,
                                    }}
                                >
                                    {drawer}
                                </Drawer>
                            </Box>

                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
                <Toolbar id='top' />

                <Container>
                    <Box sx={{ my: 2, minHeight: '90vh' }}>
                        {show.content}
                    </Box>
                </Container>

                <BackToTop />

                <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />

            </div>
        )
    } else {
        return navigate('/signIn', { replace: true });
    }

}
